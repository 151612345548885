// @use '_hero';
// @use '_about.scss';
// @use '_process.scss';
// @use '_team.scss';

@use "_vars";

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins&family=Roboto:ital,wght@0,400;1,500&display=swap";

body {
  font-family: "Poppins", sans-serif;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  line-height: 1.8;
  color: vars.$color-dark;
  text-align: left;
  background-color: vars.$color-blue-light-alt;
}

section {
  padding: 2rem 2rem;
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

h1,
h2 {
  font-weight: bold;
}

h1 {
  font-size: 2.5rem;
}

h6 {
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.btn {
  border-radius: 1rem;
}

.btn-light {
  border: 2px solid vars.$color-dark;
}

.container {
  background-color: vars.$color-blue-light;
  overflow: hidden;
}

nav {
  padding: 0 2rem !important;
  #navbarSupportedContent {
    justify-content: flex-end;
  }
  ul {
    align-items: center;
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  li {
    display: inline-block;
    margin: 0 1rem;
  }
  li a {
    text-decoration: none;
    color: vars.$color-dark;
    font-size: 1rem;
    font-weight: 600;
  }
  li img {
    transform: translateY(-3px);
  }
}

footer {
  a {
    color: vars.$color-light;
    text-decoration: none;
  }
  h3 {
    font-weight: 600;
  }
  background-color: vars.$color-gray;
  color: vars.$color-light;
  p {
    position: relative;
    padding-left: 2rem;
  }
  .icon {
    position: absolute;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    left: 0;
  }
  .info {
    display: block;
    width: max-content;
  }
  .footer-logo {
    background-image: url(../assets/footer-logo.svg);
    background-position: center;
    background-repeat: no-repeat;
    padding: 7rem;
  }
  .info-md-down {
    padding-top: 2.5rem;
  }
  .p-top {
    padding: 3rem 0;
  }
  @include media-breakpoint-down(md) {
    .footer-logo {
      background-image: url(../assets/footer-logo-wrt.svg);
      background-position: left;
      padding: 0;
      padding-bottom: 8rem;
      background-position-x: 1rem;
    }
    .info-md-down {
      padding-left: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .p-top {
      padding-top: 1rem;
      padding-left: 2rem;
    }
  }
}

// #heroText {
//   transform: translateY(-8rem);
// }

.hero {
  //height: calc(100vh - 5rem);
  height: 540px;
  color: vars.$color-light;
  background-color: vars.$color-blue-light;
  background: linear-gradient(0deg, rgba(8, 40, 94, 0.4), rgba(8, 40, 94, 0.4)), url(../assets/hero-image.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  align-content: center;
  p {
    font-size: 1.3rem;
  }
}

@include media-breakpoint-down(md) {
  .hero-mobile {
    background-size: cover !important;
    #hero-heading {
      font-size: 1.5rem;
    }
    p {
      font-size: 1rem;
    }
  }
}

.bg-light {
  background-color: vars.$color-light;
}

.bg-light-alt {
  background-color: vars.$color-light-alt;
}

.the-numbers {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  display: flex;
  gap: 1rem;
  div.clock {
    width: 64px;
    height: 40px;
    border-radius: 50px;
    background-image: url(../assets/clock.gif);
    background-position: center;
    background-size: 64px;
  }
  .number {
    color: vars.$color-light;
    width: 21rem;
    height: 7rem;
    border-radius: 1rem;
    background-color: vars.$color-light-blue;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  div.display {
    border-right: 1px solid vars.$color-light;
    padding: 0.5rem;
    margin-right: 0.5rem;
  }
  span.digit {
    font-size: 3rem;
    line-height: 2rem;
  }
  span.digit-small {
    font-size: 0.8rem;
  }
  div.text {
    font-size: 1rem;
    text-align: left;
  }
  div.icon {
    margin-left: 0.5rem;
  }
}

.carousel {
  margin-bottom: 2rem;
}

.carousel-item {
  p {
    height: 8rem;
    overflow: auto;
    @include media-breakpoint-down(md){
      height: 16rem;
    }
  }
}

.carousel .carousel-indicators button {
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
}
.carousel-indicators {
  margin-bottom: -3rem;
}

.process {
  padding: 2rem;
  h5 {
    font-weight: 600;
  }
  div.text {
    font-size: 1rem;
  }
  div.icon {
    margin-bottom: 1rem;
  }
}
#process {
  position: relative;
}
#process-contact-label {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding: 1rem;
  font-family: Roboto;
  font-size: 1.1rem;
  font-weight: 900;
  letter-spacing: 0em;
  position: absolute;
  top: 4rem;
  @include media-breakpoint-down(md) {
    top: unset;
    bottom: 4rem;
  }
  right: -4rem;
  cursor: pointer;
  transform: rotate(-90deg);
  background-color: vars.$color-dark-blue;
  color: vars.$color-light;
  :hover {
    right: -5rem;
  }
  a {
    color: vars.$color-light;
    text-decoration: none;
  }
}
.process-card {
  padding: 2rem;
  margin-bottom: 2rem;
}
.step:hover {
  border-radius: 1.5rem;
  background-color: vars.$color-light;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
}

#team div.row {
  gap: 1rem;
  justify-content: center;
  padding-bottom: 1rem;
}

@include media-breakpoint-up(lg) {
  #team div.row {
    padding-bottom: 3rem;
  }
}

.meet-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: relative;
  border-radius: 1rem;
  height: 22rem;
  padding-top: 2rem;
  border-radius: 1rem;
  background-color: vars.$color-light;
  max-width: 18rem;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
  div.image {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    background-color: vars.$color-blue-light;
    margin-bottom: 2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  div.name {
    font-weight: 600;
    color: vars.$color-light-blue;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  div.title {
    font-size: 1rem;
  }
  div.read-bio {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    background-color: vars.$color-light-blue;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: vars.$color-light;
    font-weight: 600;
    font-size: 1.2rem;
    cursor: pointer;
    a {
      color: vars.$color-light;
      text-decoration: none;
    }
  }
}

#testimonials div.col-sm-12 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.testimonial-container {
  background-color: vars.$color-light;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 2px 3px 5px 1px rgba(0, 0, 0, 0.1);
  max-width: 60rem;
  div.customer {
    color: vars.$color-light-blue;
    font-weight: 600;
  }
  div.customer-location {
    font-size: 1rem;
    color: vars.$color-gray;
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  .contact-container {
    display: flex;
    gap: 1rem;
    max-width: 60rem;
    flex-direction: column;
    form {
      max-width: 60rem;
    }
    @include media-breakpoint-down(md) {
      .row {
        gap: 1rem;
      }
    }
  }
}

.hide {
  display: none;
}

.hidden {
  visibility: hidden;
}

.modal-content {
  border-radius: 20px;
}

.modal-header {
  height: 20px;
  width: 100%;
  border-radius: 20px 20px 0 0;
  background-color: vars.$color-light-blue;
  // button {
  //   border: none;
  //   background: none;
  //   position: absolute;
  //   top: 3rem;
  //   right: 3rem;
  // }
}

.modal-close {
  display: flex;
  justify-content: flex-end;
  button {
    border: none;
    font-family: "Roboto";
    font-weight: bold;
    font-size: 1.5rem;
    color: vars.$color-light-blue;
    margin-top: 1rem;
    background: none;
    width: 2rem;
  }
}

.modal-body {
  padding: 0 3rem;
  h1 {
    color: vars.$color-light-blue;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
  }
  #title-mdl {
    color: vars.$color-gray;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
  }
  #bio-mdl {
    font-size: 0.8rem;
    padding-top: 1rem;
    font-family: "Montserrat", sans-serif;
    text-align: justify;
  }
}
